<template>
  <div class="documents">
    <div
      class="data-page__title _flex _flex-justify__between _flex-align__center _font-weight__600 _font-size__heading3"
    >
      <span>Inspection Date: {{ dateArr[2] }} {{ dateArr[1] }} {{ dateArr[3] }}</span>
      <el-button
        type="info"
        @click="handleDown"
        :disabled="!list[tabsIndex] || !list[tabsIndex].imgs.length"
        >Download Docs</el-button
      >
    </div>

    <div class="documents__tabs _flex">
      <div
        v-for="(item, index) in tabs"
        :key="item.label"
        :class="[
          'documents__tabs__item _font-weight__600 _font-size__sub__heading _pointer',
          { active: index == tabsIndex }
        ]"
        @click="handleTabChange(item, index)"
      >
        {{ item.label }}
      </div>

      <!-- 红色线 -->
      <div class="documents__tabs__border" :style="borderStyle"></div>
    </div>

    <div class="documents__content _flex">
      <div
        class="content__item"
        v-for="(item, index) in list[tabsIndex] && list[tabsIndex].imgs"
        :key="index"
      >
        <div class="content__item__image">
          <div class="content__item__image-mark _flex _flex-center">
            <el-dropdown :hide-on-click="false" @command="handleCommand($event, item, index)">
              <i class="el-icon-more"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="download">Download</el-dropdown-item>
                <el-dropdown-item command="delete">Delete drawing</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="button _font-size__sub__heading">Download</div>
          </div>

          <el-image
            :src="item.fileExt === 'pdf' ? pdfIcon : item.filePath"
            :z-index="index"
            :style="{ width: pxConvertRem(240), height: pxConvertRem(173) }"
            fit="contain"
            ref="ElImage"
            @click="handlePreview(item, index)"
          >
            <template #placeholder>
              <div
                style="width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3)"
                v-loading="true"
              ></div>
            </template>
          </el-image>
        </div>
        <div class="content__item__info">
          <span class="_font-weight__600">{{ item.fileName }}</span>
          <div>{{ createTimeRender(item.createdTime) }}</div>
        </div>
      </div>

      <SimpleUpload
        class="content__item"
        :data="{
          towerCode: towerCode,
          type: tabs[tabsIndex].type
        }"
        @success="handleSuccess"
      />
    </div>
  </div>
</template>

<script>
import { batchDownload, downloadImage, downloadPDF } from '@/utils/batch-save'
import SimpleUpload from '@/components/upload/simple-upload'
import { fileFindList, deleteOneFile } from '@/api/data'
import { parseTime } from '@/utils'

import common from '@/common'
import pdfIcon from '@/assets/pdf-icon.png'
import { componentProp, documentTabs } from '../index-data'

export default {
  components: {
    SimpleUpload
  },
  props: componentProp,
  data() {
    return {
      pdfIcon,
      towerCode: common.code,
      list: [],
      componentName: 'tilt-sensor',
      domPosition: [],
      tabsIndex: 0,
      tabs: documentTabs
    }
  },
  computed: {
    borderStyle() {
      if (this.domPosition.length === 0) return { width: '0px' }
      const style = {
        width: `${this.domPosition[this.tabsIndex].width}px`,
        transform: `translateX(${this.domPosition[this.tabsIndex].left}px)`
      }

      return style
    }
  },
  mounted() {
    this.$nextTick(() => {
      const doms = document.getElementsByClassName('documents__tabs__item')
      for (let i = 0; i < doms.length; i += 1) {
        this.domPosition.push({
          width: doms[i].clientWidth,
          left: doms[i].offsetLeft
        })
      }
    })

    this.getData()
  },
  methods: {
    handleCommand(command, item, index) {
      console.log(command, item, item.fileName)
      switch (command) {
        case 'download':
          downloadImage(item.filePath, item.fileName)
          break
        case 'delete':
          deleteOneFile(item.id).then(() => {
            this.$message.success('successfully delete')
            this.list[this.tabsIndex].imgs.splice(index, 1)
          })
          break
        default:
      }
    },
    handleSuccess(data) {
      this.list[this.tabsIndex].imgs.push(data)
    },
    handleTabChange(item, index) {
      this.tabsIndex = index
      this.componentName = item.componentName

      this.getData()
    },
    createTimeRender(time) {
      if (!time) return ''
      return `${parseTime(time, 'reg')} at ${parseTime(time, '{h}:{i} {f}')}`
    },
    getData() {
      fileFindList({
        towerCode: common.code,
        type: this.tabs[this.tabsIndex].type
      }).then((res) => {
        this.list.push({
          type: this.tabs[this.tabsIndex].type,
          imgs: res.result
        })
      })
    },

    handleDown() {
      const img = []
      this.list[this.tabsIndex].imgs.forEach((item) => {
        img.push(
          process.env.NODE_ENV === 'production' ? item.filePath : `file/${item.fileRelativePath}`
        )
      })

      batchDownload(img, `${'Tower Camera -'}${this.tabs[this.tabsIndex].label}`)
    },

    handlePreview(item, index) {
      if (item.fileExt === 'pdf') {
        downloadPDF(item.filePath)
      } else {
        this.handleCommand('download', item, index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.documents {
  &__tabs {
    position: relative;
    height: 35px;
    margin-bottom: 30px;

    &__item {
      transition: all 0.3s;
      line-height: 35px;
      &.active {
        color: var(--color-primary);
      }
    }

    &__item + .documents__tabs__item {
      margin-left: 40px;
    }

    &__border {
      position: absolute;
      transition: all 0.3s;
      bottom: 0;
      left: 0;
      height: 3px;
      background: var(--color-primary);
    }
  }

  &__content {
    padding: 30px;
    background: var(--color-white);

    .content__item {
      position: relative;
      width: 240px;
      height: 220px;
      box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);

      & + .content__item {
        margin-left: 20px;
      }

      &__image {
        width: 100%;
        height: 173px;
        position: relative;
        background: #f5f7fa;

        &:hover .content__item__image-mark {
          opacity: 1;
          pointer-events: none;
        }

        &-mark {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          opacity: 0;
          transition: all 0.3s;
          background: rgba(155, 155, 155, 0.6);
          backdrop-filter: blur(2px);

          .button {
            width: 130px;
            height: 35px;
            line-height: 35px;
            border-radius: 3px;
            text-align: center;
            color: var(--color-white);
            border: 1px solid var(--color-white);
          }

          ::v-deep .el-dropdown {
            position: absolute;
            top: 10px;
            right: 10px;
            color: var(--color-white);
            z-index: 2;
            pointer-events: auto;
          }
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      &__info {
        padding: 7px;
        font-size: 12px;
        background: #f8f8f8;
        border-radius: 5px;

        > span {
          display: block;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 使用省略号 */
        }

        > div {
          margin-top: 5px;
        }
      }

      &__pdf-icon {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.el-dropdown-menu {
  padding: 0;
  background: var(--color-info-light-3);
  border: none;

  &__item {
    color: var(--color-white);
    font-size: 12px;
    &:hover,
    &:focus {
      color: var(--color-white);
      background: var(--color-info);
    }
  }
}

::v-deep .popper__arrow::after {
  border-bottom-color: var(--color-info-light-3) !important;
}
</style>
