var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documents"},[_c('div',{staticClass:"data-page__title _flex _flex-justify__between _flex-align__center _font-weight__600 _font-size__heading3"},[_c('span',[_vm._v("Inspection Date: "+_vm._s(_vm.dateArr[2])+" "+_vm._s(_vm.dateArr[1])+" "+_vm._s(_vm.dateArr[3]))]),_c('el-button',{attrs:{"type":"info","disabled":!_vm.list[_vm.tabsIndex] || !_vm.list[_vm.tabsIndex].imgs.length},on:{"click":_vm.handleDown}},[_vm._v("Download Docs")])],1),_c('div',{staticClass:"documents__tabs _flex"},[_vm._l((_vm.tabs),function(item,index){return _c('div',{key:item.label,class:[
        'documents__tabs__item _font-weight__600 _font-size__sub__heading _pointer',
        { active: index == _vm.tabsIndex }
      ],on:{"click":function($event){return _vm.handleTabChange(item, index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),_c('div',{staticClass:"documents__tabs__border",style:(_vm.borderStyle)})],2),_c('div',{staticClass:"documents__content _flex"},[_vm._l((_vm.list[_vm.tabsIndex] && _vm.list[_vm.tabsIndex].imgs),function(item,index){return _c('div',{key:index,staticClass:"content__item"},[_c('div',{staticClass:"content__item__image"},[_c('div',{staticClass:"content__item__image-mark _flex _flex-center"},[_c('el-dropdown',{attrs:{"hide-on-click":false},on:{"command":function($event){return _vm.handleCommand($event, item, index)}}},[_c('i',{staticClass:"el-icon-more"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"download"}},[_vm._v("Download")]),_c('el-dropdown-item',{attrs:{"command":"delete"}},[_vm._v("Delete drawing")])],1)],1),_c('div',{staticClass:"button _font-size__sub__heading"},[_vm._v("Download")])],1),_c('el-image',{ref:"ElImage",refInFor:true,style:({ width: _vm.pxConvertRem(240), height: _vm.pxConvertRem(173) }),attrs:{"src":item.fileExt === 'pdf' ? _vm.pdfIcon : item.filePath,"z-index":index,"fit":"contain"},on:{"click":function($event){return _vm.handlePreview(item, index)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticStyle:{"width":"100%","height":"100%","background":"rgba(0, 0, 0, 0.3)"}})]},proxy:true}],null,true)})],1),_c('div',{staticClass:"content__item__info"},[_c('span',{staticClass:"_font-weight__600"},[_vm._v(_vm._s(item.fileName))]),_c('div',[_vm._v(_vm._s(_vm.createTimeRender(item.createdTime)))])])])}),_c('SimpleUpload',{staticClass:"content__item",attrs:{"data":{
        towerCode: _vm.towerCode,
        type: _vm.tabs[_vm.tabsIndex].type
      }},on:{"success":_vm.handleSuccess}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }